import { useEffect, useState } from "react";
import useService from '../useService';
import Profile from '../entity/profile';

export default function Login(props) {
  const [inputData, setInputData] = useState(null);
  const [report, setReport] = useState({});

  const handleChange = (event) => {
    props.sil(prv => ({ ...prv, [event.target.name]: event.target.value }));
  };

  const service = useService();

  const handleSubmit = (event) => {
    setReport({});

    let profile = new Profile();
    profile.username = props.il['user'];
    profile.password = props.il['pass'];
    profile.lastname = 'lastname';
    profile.firstname = 'firstname';
    profile.preference = 'preference';

    event.preventDefault();
    // service.postData(inputLogin)
    service.Auth(profile)
      .then(async (data) => {
        props.sil(prv => ({ ...prv, authorize: true }));
        props.sil(prv => ({ ...prv, response: 'Response: ' + data.data.message + " \n  Status: " + data.statusText }));
        setTimeout(() => {
          props.sil(prv => ({ ...prv, route: true }));
        }, 3000);

        var response = data.status == 200 ? 'Login Success -'+ profile.username +' Authenticated' : 'Transaction Failed'
        setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
      })
      .catch((err) => {
        props.sil(prv => ({ ...prv, user: '' }));
        props.sil(prv => ({ ...prv, pass: '' }));
        props.sil(prv => ({ ...prv, authorize: false }));

        if (err?.response != null)
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
        else
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
      });
  };

  return (
    <div className='login-main'>
      {report['ishttp'] != null ? <p className={report['ishttp']}>  {report['response']}  </p> : ''}

      <div class="login-internal">
        <div className='login-username'>
          <label for="" className="login-label">Type Username</label>
          <input
            type="text"
            name="user"
            onChange={handleChange}
            className='login-input form-control fs-5'
            id="username"
            value={props.il['user']}
          />
        </div>
        <div className='login-password'>
          <div className='form-group'>
            <label for="" className="login-label">Type Password</label>
            <input
              type="password"
              name="pass"
              // value={inputLogin.password}
              onChange={handleChange}
              className='login-input form-control fs-5'
              id="password"
              value={props.il['pass']}
            />
          </div>
        </div>
      </div>
      <div className='login-action'>
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

