import { useEffect, useState } from "react";
import useService from '../useService';
import Select from 'react-select'
import './insert.css';

export default function Insert(props) {
  const [options, setOptions] = useState({});
  const [dataInput, setDataInput] = useState({ data: '' });
  const [assetValue, setAssetValue] = useState({});
  const [fksResult, setFksResult] = useState([]);
  const [tableSchema, settableSchema] = useState([]);
  const service = useService();
  const [report, setReport] = useState({});
  const [tableData, setTableData] = useState({ table: '', key: '' });

  let assetList = [];
  window.myGlobalVariable = { user: 'Jane Doe' };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      boxShadow: '0 2px 4px rgba(0,0,0,.2)',
      height: '38px',
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'hotpink' : 'white',
    }),
  };

  function getTableSchema() {
    var idx;
    service.getTableSchema()
      .then(async (data) => {
        var tables = Object.keys(data.data).map(k => { return { label: k, value: k }; }).filter(kk => { return kk.value != 'userprofile' && kk.value != 'report' });
        setOptions(prv => ({ ...prv, asset: tables }));
        Object.keys(data.data).forEach(k => {
          data.data[k].forEach(kk => {
            var tt = Object.keys(kk);
            var x = kk[tt[0]];
            kk[tt[0]] = x == 'timestamp' ? 'date' : x == 'integer' || x == 'double' ? 'number' : x == 'boolean' ? 'boolean' : 'text';
          });
        });

        for (const [key, value] of Object.entries(data.data)) {
          idx = value.findIndex(x =>
            Object.keys(x)[0] == 'id'
          );
          if (idx >= 0)
            data.data[key].splice(idx, 1);
        };

        settableSchema(data.data);
        var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
        setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
      })
      .catch((err) => {
        if (err?.response != null)
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
        else
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
      });
  }
  useEffect(() => {
    getTableSchema();
  }, []);

  async function getSelection(tech, event) {
    //            setTableData(prv => ({ ...prv, ['fk']: prv['fkorig'] }));
    setTableData(prv => ({ ...prv, ['table']: {} }));
    setTableData(prv => ({ ...prv, ['key']: {} }));
    setReport({});
    setAssetValue({});
    var fksListTmp = {};
    var fks = [];
    setFksResult([]);
    fksResult.length = 0;
    var fksList = [];
    dataInput['asset'] = event.label;

    fks = props.schema.filter(k => { return k.table == event.label });

    if (fks.length > 0) {
      await service.getFks(fks)
        .then(async (data) => {
          setFksResult(data.data);
          data.data.map(k => {
            (k.data).forEach(kk => {
              // fksList.push({ asset: k.key, value: kk, label: kk });
              fksList.push({ asset: k.key, value: kk.id, label: kk.name });
            });
            fksListTmp[k.key] = fksList.map(k => { return k });
            setOptions(prv => ({ ...prv, [k.key]: fksListTmp[k.key] }));
            fksList = [];
          });

          var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
          //  setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
        })
        .catch((err) => {
          if (err?.response != null)
            setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
          else
            setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
        });
    }
  };

  function getData(evt) {
    var key = evt['asset'] != null ? evt['asset'] : evt.target.name;
    var value = evt['value'] != null ? evt['value'] : evt.target.value;
    value = value == 'true' ? true : value == 'false' ? false : value;
    // if (value == '')
    //   return null;
    setAssetValue(prv => ({ ...prv, [key]: value }));
  };

  function clear() {
    var tmp = {};
    for (const [key, value] of Object.entries(assetValue)) {
      tmp[key] = '';
    }
    setAssetValue(tmp);
  }

  function submit(evt) {
    setReport({});
    var tmp = {};
    tmp[dataInput['asset']] = assetValue;
    var t = assetList[0];
    clear();
    // return null;
    service.postAssetData(tmp)
      .then(async (data) => {
        //        var ff=Object.assign(Object.keys( data.data));
        setTableData(prv => ({ ...prv, ['table']: data.data }));
        setTableData(prv => ({ ...prv, ['key']: Object.keys(data.data[0]) }));
        var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
        setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
        clear();
      })
      .catch((err) => {
        if (err?.response != null)
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
        else
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
        clear();
      });
  };

  function removeFirstAndLast(str) {
    const arr = str.split('');
    arr.shift();
    arr.pop();
    return arr.join('');
  }

  return (
    <div className='insert-main'>
      <div className="insert-select">
        <Select options={options['asset']} onChange={(e) => getSelection("asset", e)} className="select" styles={customStyles} placeholder='Select Asset' />
      </div>
      <div className="insert-action">
        <button onClick={submit}>Insert</button>
      </div>

      <div className="insert-data">
        <div>
          {dataInput['asset'] != null ?
            tableSchema[dataInput['asset']].map(k => (
              <div >
                <label className="name"> {Object.keys(k)[0]}</label>
                {fksResult.length > 0 && fksResult.filter(kk => { return kk.key == Object.keys(k)[0] }).length > 0 ?
                  fksResult.filter(kk => { return kk.key == Object.keys(k)[0] }).map(kk => (
                    <select onChange={(evt) => getData(evt)} name={Object.keys(k)[0]} className="select">
                      <option></option>
                      {options[kk.key].map(x => (<option value={x.value}  >{x.label}</option>))}
                    </select>
                  ))
                  : k[Object.keys(k)[0]] == 'boolean' ? <select onChange={(evt) => getData(evt)} name={Object.keys(k)[0]}><option >--select option-- </option>  <option>true</option ><option>false</option>  </select>
                    : <input name={Object.keys(k)[0]} type={k[Object.keys(k)[0]]} onChange={(evt) => getData(evt)} className='input' value={assetValue[Object.keys(k)[0]]} />
                }
              </div>
            )) : ''
          }
        </div>
      </div>

      {Object.keys(tableData['table']).length > 0 && (
        <div className="insert-table">
          <table>
            <thead>
              <tr>
                {tableData['key'].map(x => (
                  (() => {
                    if (x != 'id') {
                      return (
                        <th>{x}</th>
                      )
                    }
                  })()
                ))
                }
              </tr>
            </thead>
            <tbody>
              {tableData['table'].length > 0 ?
                tableData['table'].map(k => (
                  <tr>
                    {tableData['key'].map(kk => (
                      (() => {
                        if (kk != 'id') {
                          return (
                            k[kk] == true ? <td>true</td> : k[kk] == false ? <td>false</td> : <td>{k[kk]}</td>
                          )
                        }
                      })()
                    ))}
                  </tr>
                )) : ''}
            </tbody>
          </table>
        </div>
      )}


      {report['ishttp'] != null ? <p className={report['ishttp']}>  {report['response']}  </p> : ''}
    </div>
  );
};