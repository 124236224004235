import { useEffect, useState } from "react";
import useService from '../useService';
import './delete.css';
import Select from 'react-select';
import moment from 'moment';

export default function Delete(props) {
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [options, setOptions] = useState({});
  const [dataInput, setDataInput] = useState({});
  const [assetValue, setAssetValue] = useState({});
  const [tableKey, setTableKey] = useState([]);
  const [tableData, setTableData] = useState({ table: '', orig: '', fk: '' });
  const [control, setControl] = useState({});
  const [fksResult, setFksResult] = useState([]);
  const [tableSchema, settableSchema] = useState([]);
  const service = useService();
  const [rowData, setRowData] = useState({});
  const [rowDataKey, setRowDataKey] = useState({});
  const [filters, setFilters] = useState({});
  const [report, setReport] = useState({});
  const [tableKeyFk, setTableKeyFk] = useState([]);
  const [controlFlag, setControlFlag] = useState(false);
  const [fkValue, setFkValue] = useState({});
  const [color, setColor] = useState();
  const [preRow, setPreRow] = useState('');
  const [isCleared, setIsCleared] = useState(false);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      boxShadow: '0 2px 4px rgba(0,0,0,.2)',
      height: '38px',
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'hotpink' : 'white',
    }),
  };

  function getTableSchema() {
    var idx;
    service.getTableSchema()
      .then(async (data) => {
        var tables = Object.keys(data.data).map(k => { return { label: k, value: k }; }).filter(kk => { return kk.value != 'userprofile' && kk.value != 'report' });
        setOptions(prv => ({ ...prv, ['table']: tables }));
        Object.keys(data.data).forEach(k => {
          data.data[k].forEach(kk => {
            var x = kk[Object.keys(kk)[0]];
            kk[Object.keys(kk)[0]] = x == 'timestamp' ? 'date' : x == 'integer' || x == 'double' ? 'number' : x == 'boolean' ? 'boolean' : 'text';
          });
        });

        for (const [key, value] of Object.entries(data.data)) {
          idx = value.findIndex(x =>
            Object.keys(x)[0] == 'id'
          );
          if (idx >= 0)
            data.data[key].splice(idx, 1);
        };
        settableSchema(data.data);
        var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
        setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
      })
      .catch((err) => {
        if (err?.response != null)
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
        else
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
      });
  }
  useEffect(() => {
    getTableSchema();
  }, []);

  function getSelection(tech, event) {
    setTableData(prv => ({ ...prv, ['table']: {} }));
    setTableData(prv => ({ ...prv, ['orig']: {} }));
    setTableData(prv => ({ ...prv, ['fk']: {} }));
    setTableData(prv => ({ ...prv, ['fkorig']: {} }));
    setControlFlag(false);
    setReport({});
    setRowDataKey({});
    setControl(prv => ({ ...prv, 'visible': false }));
    dataInput['asset'] = event.label;
    getFks();
    service.getAssetData(event.label)
      .then(async (data) => {

        if (Object.keys(data.data).length == 0 || data.data === null || data.data === 'undefined') {
          return null;
        }
        data.data.map(row => {
          Object.keys(row).map(rowkey => {
            var idx = tableSchema[event.label].findIndex(x => Object.keys(x)[0] == rowkey);
            if (idx >= 0 && tableSchema[event.label][idx][rowkey] == 'date') {
              const formattedDate = moment(row[rowkey]);
              var s = formattedDate.format('YYYY-MM-DD');
              row[rowkey] = s;
            }
          });
        });

        var listTmpRows = cloneTableData(data.data);
        setTableData(prv => ({ ...prv, ['table']: data.data }));
        setTableData(prv => ({ ...prv, ['orig']: listTmpRows }));
        setTableKey(Object.keys(data.data[0]));

        getRow(null, null, false);
        var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
        setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
      })
      .catch((err) => {
        if (err?.response != null)
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
        else
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
      });
  };

  async function getFks() {
    setReport({});
    var fksListTmp = {};
    var fks = [];
    setFksResult([]);
    fksResult.length = 0;
    var fksList = [];
    fks = props.schema.filter(k => { return k.table == dataInput['asset'] });

    if (fks.length > 0) {
      setControlFlag(true);
      await service.getFks(fks)
        .then(async (data) => {
          setFksResult(data.data);
          data.data.map(k => {
            (k.data).forEach(kk => {
              // // fksList.push({ asset: k.key, value: kk, label: kk });
              fksList.push({ asset: k.key, value: kk.name, label: kk.name });

            });
            fksListTmp[k.key] = fksList.map(k => { return k });
            setOptions(prv => ({ ...prv, [k.key]: fksListTmp[k.key] }));
            fksList = [];
          });
          props.sil(prv => ({ ...prv, response: 'Response: ' + data.data.message + " \n  Status: " + data.statusText, ishttp: 'ok' }));
        })
        .catch((err) => {
          if (err?.response != null)
            setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
          else
            setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
        });
    }
  };

  const getRow = (evt = null, rowdata = null, flag = true) => {
    setIsCleared(false);
    var tmp = {};
    var obj = {};

    if (flag) {
      evt.currentTarget.className = 'testcolor';
      var tt = preRow;
      if (preRow != '')
        preRow.className = 'standardcolor';
      setPreRow(evt.currentTarget);
    }

    obj[dataInput['asset']] = [];

    if (flag) {
      Object.keys(rowdata).forEach(k => {
        tmp[k] = rowdata[k] == true ? 'true' : rowdata[k] == false ? 'false' : rowdata[k];
      });
      setRowData(tmp);
      setControl(prv => ({ ...prv, 'visible': true }));
      setIsRowSelected(true);
    }

    var schema = props.schema.filter(k => { return k['table'] == dataInput['asset'] });
    if (flag && schema.length > 0) {
      for (var k in rowdata) {
        schema.forEach(kk => {
          if (kk['foreignkey'] == k) {
            var t = {};
            t['key'] = k;
            t['value'] = rowdata[k];
            t['fktable'] = kk['foreigntable'];
            t['keys'] = tableSchema[kk['foreigntable']].map(x => { return Object.keys(x)[0]; });
            t['flag'] = flag;
            obj[dataInput['asset']].push(t);
          }
        });
      };
    }
    else if (!flag && schema.length > 0) {
      schema.forEach(kk => {
        var t = {};
        t['fktable'] = kk['foreigntable'];
        t['key'] = kk['foreignkey']
        t['keys'] = tableSchema[kk['foreigntable']].map(x => { return Object.keys(x)[0]; });
        t['flag'] = flag;
        obj[dataInput['asset']].push(t);
      });
    }

    if (flag || obj[dataInput['asset']].length == 0) {
      return null;
    }

    service.postAssetDataFk(obj)
      .then(async (data) => {

        var tables = props.schema.filter(k => { return k.table == dataInput['asset'] }).map(kk => { return kk.foreigntable });
        for (var table of tables) {
          data.data.map(row => {
            Object.keys(row).map(rowkey => {
              var idx = tableSchema[table].findIndex(x => Object.keys(x)[0] == rowkey.split('_')[1]);
              if (idx >= 0 && tableSchema[table][idx][rowkey.split('_')[1]] == 'date') {
                const formattedDate = moment(row[rowkey]);
                var s = formattedDate.format('YYYY-MM-DD');
                row[rowkey] = s;
              }
            });
          });
        }

        if (!flag) {
          var listTmpRows = cloneTableData(data.data);
          setTableData(prv => ({ ...prv, ['fkorig']: listTmpRows }));
          // controlflag = true;
        }
        setTableData(prv => ({ ...prv, ['fk']: data.data }));
        setTableKeyFk(Object.keys(data.data[0]));
      })
      .catch((err) => {
        if (err?.response != null)
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
        else
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
      });
  }

  const getRowFk = (evt, rowdata) => {
    setIsCleared(false);
    setIsRowSelected(true);
    var tmp = {};
    var obj = {};
    var tmpFk = {};
    var tmpfFkValue;

    evt.currentTarget.className = 'testcolor';
    var tt = preRow;
    if (preRow != '')
      preRow.className = 'standardcolor';
    setPreRow(evt.currentTarget);

    obj[dataInput['asset']] = [];
    var keys = {};

    var res = Object.keys(rowdata).filter(k => { return k.split('_')[1] == 'name' });

    for (var k of res) {
      tmp[k.split('_')[0] + 'id'] = rowdata[k];
    }

    tmpFk[dataInput['asset']] = {};
    if (controlFlag) {
      for (var k in tmp) {
        tmpFk[dataInput['asset']][k] = tmp[k];
        tmpFk[dataInput['asset']][k] = fksResult.find(kk => { return kk.key == k })['data'].find(kkk => {
          return kkk.name == tmp[k]
        })['id'];
      }
      var ks = Object.keys(tmpFk[dataInput['asset']]);
      keys = tableData['table'].find(k => {
        return k[ks[0]] == (tmpFk[dataInput['asset']])[ks[0]] && k[ks[1]] == (tmpFk[dataInput['asset']])[ks[1]];
      })
    }

    setFkValue(keys);
    setRowData(tmp);
    setControl(prv => ({ ...prv, 'visible': true }));
    setIsRowSelected(true);
  }

  function getData(evt) {
    setIsCleared(false);
    var tmp = isRowSelected ? rowData : rowDataKey;
    var rowDataKeyNew = {};
    var obj = {};
    var kk = evt.target.name;
    var value = evt.target.value;

    if (value == 'true')
      tmp[kk] = true;
    else if (value == 'false')
      tmp[kk] = false;
    else if (value == '')
      delete (tmp[kk]);
    else if (!isNaN(value)) {
      tmp[kk] = parseInt(value);
    }
    else
      tmp[kk] = value;

    if (isRowSelected) {
      setRowData(tmp);
    }
    else {
      setRowDataKey(tmp);
    }

    for (var key in rowDataKey) {
      var kk;
      rowDataKeyNew[key] = rowDataKey[key];
      if (controlFlag) {
        kk = key.split('id')[1] == '' ? key.split('id')[0] + '_name' : '';
        obj[kk] = rowDataKeyNew[key];
        continue;
      }
      obj[key] = rowDataKeyNew[key];
    };

    if (controlFlag) {
      var listTmpRows = cloneTableData(tableData['fkorig']);
      var res = recorsiveGetData(listTmpRows, obj);
      setTableData(prv => ({ ...prv, ['fk']: res }));

    }
    else {
      var listTmpRows = cloneTableData(tableData['orig']);
      var result = recorsiveGetData(listTmpRows, rowDataKeyNew);
      setTableData(prv => ({ ...prv, ['table']: result }));
    }
  }

  function recorsiveGetData(data, filters) {
    if ((Object.keys(filters)).length == 0) {
      return data;
    };
    var key = Object.keys(filters)[0];
    data = filters[key] == true || filters[key] == false || !isNaN(filters[key]) ? data.filter(k => { return k[key] == (filters[key]) }) : data.filter(k => { return k[key].startsWith(filters[key]) });
    delete (filters[key]);
    var res = recorsiveGetData(data, filters);
    return res;
  };

  function clear() {
    setIsCleared(true);
    var table = isRowSelected ? rowData : rowDataKey;
    var tmpRowDataKey = {};

    Object.keys(table).forEach(k => {
      tmpRowDataKey[k] = null;
    });
    setRowData(tmpRowDataKey);
    setRowDataKey(tmpRowDataKey);
    setRowData({});
    setRowDataKey({});

    if (controlFlag) {
      setTableData(prv => ({ ...prv, ['fk']: prv['fkorig'] }));
      setIsRowSelected(false);
    }
    else {
      setTableData(prv => ({ ...prv, ['table']: prv['orig'] }));
      setTableData(prv => ({ ...prv, ['fk']: '' }));
      setIsRowSelected(false);
    }
  }

  function cloneTableData(tableData) {
    var rows = {};
    var listRows = [];
    tableData.map(k => {
      for (var key in k) {
        rows[key] = k[key];
      };
      listRows.push(rows);
      rows = {};
    });
    return listRows;
  }

  function submit(evt) {
    var ttt;
    setReport({});
    var tmp = {};
    var tmpFk = {};

    tmp['table'] = dataInput['asset'];
    tmp['id'] = rowData['id'];
    tmp['foreignkey'] = 'id';

    if (controlFlag) {
      tmp['id'] = fkValue['id'];
    }

    var fkTables = props.schema.filter(k => { return k.foreigntable == dataInput['asset'] });
    for (var item of fkTables) {
      item['id'] = rowData['id'];
    }
    fkTables.push(tmp);
    // return null;

    service.deleteAssetData(fkTables)
      .then(async (data) => {
        var listTmpRows = cloneTableData(data.data);

        if (controlFlag) {
          var fknew = tableData['fkorig'].filter(k => {
            return ((k[Object.keys(rowData)[0].split('id')[0] + '_name'] != rowData[Object.keys(rowData)[0]]) ||
              (k[Object.keys(rowData)[1].split('id')[0] + '_name'] != rowData[Object.keys(rowData)[1]]));
          });
          setTableData(prv => ({ ...prv, ['fkorig']: fknew }));
          setTableData(prv => ({ ...prv, ['fk']: fknew }));
        }
        setTableData(prv => ({ ...prv, ['table']: data.data }));
        setTableData(prv => ({ ...prv, ['orig']: listTmpRows }));

        setTableKey(Object.keys(data.data[0]));
        getRow(null, null, false);
        clear();

        if (!controlFlag)
          deleteReportData(rowData);

        var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
        setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
      })
      .catch((err) => {
        if (err?.response != null)
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
        else
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
      });
  };

  function deleteReportData(rowdata) {
    var tmp = {};
    var listTmp = [];

    tmp['table'] = 'report';
    tmp['id'] = rowdata['name'];
    tmp['foreignkey'] = dataInput['asset'];

    listTmp.push(tmp);
    // return null;
    service.deleteAssetData(listTmp)
      .then(async (data) => {
        var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
        setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
      })
      .catch((err) => {
        if (err?.response != null)
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
        else
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
      });
  }

  return (
    <div className='delete-main'>

      <div className="delete-select">
        <Select options={options['table']} onChange={(e) => getSelection("asset", e)} className="select" styles={customStyles} placeholder='Select Asset' />
      </div>

      <div className="delete-button">
        <div className="delete-clear">
          <button onClick={clear}>Clear</button>
        </div>
        <div className="delete-action">
          <button onClick={submit}>Delete</button>
        </div>
      </div>

      <div className="delete-data">
        <div>
          {dataInput['asset'] != null ?
            tableSchema[dataInput['asset']].map(k => (
              <div>
                <label className="name"> {Object.keys(k)[0]}</label>
                {fksResult != null && fksResult.length > 0 && fksResult.filter(kk => { return kk.key == Object.keys(k)[0] }).length > 0 ?
                  fksResult.filter(kk => { return kk.key == Object.keys(k)[0] }).map(x => (
                    <select onChange={(evt) => getData(evt)} name={Object.keys(k)[0]}>
                      <option style={{color:'red',fontWeight: 700,fontSize: "25px"}} selected={!isCleared && !isRowSelected ? rowDataKey[x.key] : !isCleared && isRowSelected ? rowData[Object.keys(k)[0]] : ''}>
                        {!isCleared && !isRowSelected ? rowDataKey[x.key]: !isCleared && isRowSelected ? rowData[Object.keys(k)[0]] : ''}</option>
                      {options[x.key].map(xx => (
                        <option value={xx.value}>{xx.label}</option>
                      ))}
                    </select>
                  ))
                  : k[Object.keys(k)[0]] == 'boolean' ? <select onChange={(evt) => getData(evt)} name={Object.keys(k)[0]}> <option selected></option><option>true</option ><option>false</option></select>
                    : <input name={Object.keys(k)[0]} type={k[Object.keys(k)[0]]} readonly={Object.keys(k)[0]=='name' && isRowSelected ?'readonly':false} onChange={(evt) => getData(evt)} value={!isCleared && !isRowSelected ? rowDataKey[Object.keys(k)[0]] :
                      !isCleared && isRowSelected ? rowData[Object.keys(k)[0]] : ''} />
                }
              </div>
            )) : ''
          }
        </div>
      </div>

      {tableData['table'].length > 0 && !controlFlag && (
        <div className="delete-table">
          <table>
            <thead>
              <tr>
                {tableKey.map(x => (
                  (() => {
                    if (x != 'id') {
                      return (
                        <th>{x}</th>
                      )
                    }
                  })()
                ))
                }
              </tr>
            </thead>
            <tbody>
              {tableData['table'].length > 0 ?
                tableData['table'].map(k => (
                  <tr onClick={(evt) => getRow(evt, k)} style={{
                    backgroundColor: color
                  }}>
                    {tableKey.map(kk => (
                      (() => {
                        if (kk != 'id') {
                          return (
                            k[kk] == true ? <td>true</td> : k[kk] == false ? <td>false</td> : <td>{k[kk]}</td>
                          )
                        }
                      })()
                    ))}
                  </tr>
                )) : ''}
            </tbody>
          </table>
        </div>
      )}

      {tableData['fk'].length > 0 && controlFlag && (
        <div className="delete-table">
          <table>
            <thead>
              <tr>
                {tableKeyFk.map(x => (
                  (() => {
                    if (x != 'id') {
                      return (
                        <th>{x}</th>
                      )
                    }
                  })()
                ))
                }
              </tr>
            </thead>
            <tbody>
              {tableData['fk'].length > 0 ?
                tableData['fk'].map(k => (
                  <tr onClick={(evt) => getRowFk(evt, k)} style={{
                    backgroundColor: color
                  }}>
                    {tableKeyFk.map(kk => (
                      (() => {
                        if (kk != 'id') {
                          return (
                            <td>{k[kk]}</td>
                            // k[kk] != true && k[kk] != false? <td>true</td> : k[kk] == false ? <td>false</td> : <td>{k[kk]}</td>
                          )
                        }
                      })()
                    ))}
                  </tr>
                )) : ''}
            </tbody>
          </table>
        </div>
      )}

      {report['ishttp'] != null ? <p className={report['ishttp']}>  {report['response']}  </p> : ''}
    </div>
  );
};







