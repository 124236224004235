import { useEffect, useState } from "react";
import "./test.css";

export default function Test(props) {

  const [color, setColor] = useState();
  const [preRow, setPreRow] = useState('');
  const [count, setCount] = useState([1, 2, 3, 4, 5]);

  const click = (evt) => {
    evt.currentTarget.className = 'testcolor';
    var tt = preRow;
    if (preRow != '')
      preRow.className = 'standardcolor';
    setPreRow(evt.currentTarget);
var res=    removeFirstAndLast('ciao');
  }

  function removeFirstAndLast(str) {
    const arr = str.split('');
    arr.shift();
    arr.pop();
    return arr.join('');
  }
  // useEffect(() => {
  //   document.body.style.backgroundColor = color
  // }, [color])

  return (<div className="App">

    <table>
      <thead>
        <tr onClick={(evt) => click(evt, 'standardcolor')} className={color}>
          <th>uno</th>
          <th>due</th>
          <th>tre</th>
        </tr>
      </thead>
      <tbody>
        {count.map(k => (
          <tr onClick={(evt) => click(evt)} id={k} style={{
            color: color
          }}>
            <td>uno</td>
            <td>due</td>
            <td>tre</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>)
}
