// import { useEffect, useState } from "react";
// import useService from '../useService';
// import Select from 'react-select'
// import './profileupdate.css';

// export default function Profileupdate(props) {
//     const [options, setOptions] = useState({});
//     const [dataInput, setDataInput] = useState({});
//     const [dataInputRow, setDataInputRow] = useState({});
//     const [assetValue, setAssetValue] = useState({ 'data': '', 'value': '' });
//     const [fksResult, setFksResult] = useState([]);
//     const [tableKey, setTableKey] = useState([]);
//     const [tableData, setTableData] = useState({ table: '', orig: '', fk: '' });
//     const [tableSchema, settableSchema] = useState([]);
//     const service = useService();
//     const [report, setReport] = useState({});
//     const [flag, setFlag] = useState(true);
//     const [isRowSelected, setIsRowSelected] = useState(false);
//     const [color, setColor] = useState();
//     const [preRow, setPreRow] = useState('');

//     const customStyles = {
//         control: (provided) => ({
//             ...provided,
//             backgroundColor: 'white',
//             boxShadow: '0 2px 4px rgba(0,0,0,.2)',
//             height: '38px',
//         }),
//         option: (provided, state) => ({
//             ...provided,
//             borderBottom: '1px dotted pink',
//             color: state.isSelected ? 'white' : 'black',
//             backgroundColor: state.isSelected ? 'hotpink' : 'white',
//         }),
//     };

//     async function getProfile() {
//         var obj = {};
//         var tables = ['service', 'customer', 'people', 'technology'];
//         await service.postProfile(tables)
//             .then(async (data) => {
//                 var res = data.data;
//                 for (var k in res) {
//                     obj[k] = [];
//                     res[k].forEach(kk => {
//                         obj[k].push({ value: kk.id, label: kk.name });
//                     });
//                 }
//                 setAssetValue(prv => ({ ...prv, ['data']: data.data }));
//                 setOptions(obj);

//                 service.getAssetData('report')
//                     .then(async (data) => {

//                         if (Object.keys(data.data).length == 0 || data.data === null || data.data === 'undefined') {
//                             return null;
//                         }

//                         var listTmpRows = cloneTableData(data.data);
//                         setTableData(prv => ({ ...prv, ['table']: data.data }));
//                         setTableData(prv => ({ ...prv, ['orig']: listTmpRows }));
//                         setTableKey(Object.keys(data.data[0]));

//                         var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
//                         setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
//                     })
//                     .catch((err) => {
//                         if (err?.response != null)
//                             setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
//                         else
//                             setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
//                     });

//                 var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
//             })
//             .catch((err) => {
//                 if (err?.response != null)
//                     setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
//                 else
//                     setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
//             });
//     };
//     useEffect(() => {
//         getProfile();
//     }, []);

//     const selectOption = (
//         <div className="profileupdate-data">
//             <div className="profileupdate-value">
//                 {Object.keys(assetValue['data']).map(k => (
//                     <div className="profileupdate-input">
//                         {/* <Select value={assetValue['value'][k]} options={options[k]} onChange={(e) => getData(e, k)} className="select" styles={customStyles} placeholder={'select ' + k} /> */}
//                         <select onChange={(evt) => getData(evt, k)} className="select" placeholder={'select ' + k} value={dataInput[k]}>
//                             <option selected={dataInput[k]}>{dataInput[k]}</option>
//                             {assetValue['data'][k].map(xx => (<option value={xx.name}>{xx.name}</option>))}
//                         </select>
//                     </div>
//                 ))}
//                 <div className="profileupdate-input">
//                     <input value={dataInput['duration']} name='duration' type="number" onChange={(evt) => getData(evt, 'duration')} className='input' placeholder='duration' />
//                 </div>
//                 <div className="profileupdate-input">
//                     <input type="text" onChange={(evt) => getData(evt, 'note')} className='input' value={dataInput['note']} name='note' placeholder='note' />
//                 </div>
//                 <div className="profileupdate-input">
//                     <select onChange={(evt) => getData(evt, 'mode')} value={dataInput['mode']}>
//                         <option value="" selected> </option>
//                         <option value="remote">Remote</option>
//                         <option value="moving">Moving</option>
//                         <option value="office">Office</option>
//                     </select>
//                 </div>
//                 <div className="profileupdate-input">
//                     <select onChange={(evt) => getData(evt, 'skill')} value={dataInput['skill']}>
//                         <option value="" selected> </option>
//                         <option value="low">Low</option>
//                         <option value="medium">Medium</option>
//                         <option value="high">High</option>
//                     </select>
//                 </div>
//             </div>
//         </div>
//     );

//     const selectOptionRow = (
//         <div className="profileupdate-data">
//             <div className="profileupdate-value">
//                 {Object.keys(assetValue['data']).map(k => (
//                     <div className="profileupdate-input">
//                         <select onChange={(evt) => getData(evt, k)} className="select" placeholder={'select ' + k} value={dataInputRow[k]}>
//                             <option selected={dataInputRow[k]}>{dataInputRow[k]}</option>
//                             {assetValue['data'][k].map(xx => (<option value={xx.name}>{xx.name}</option>))}
//                         </select>
//                     </div>
//                 ))}
//                 <div className="profileupdate-input">
//                     <input value={dataInputRow['duration']} name='duration' type="number" onChange={(evt) => getData(evt, 'duration')} className='input' placeholder='duration' />
//                 </div>
//                 <div className="profileupdate-input">
//                     <input type="text" onChange={(evt) => getData(evt, 'note')} className='input' value={dataInputRow['note']} name='note' placeholder='note' />
//                 </div>
//                 <div className="profileupdate-input">
//                     <select onChange={(evt) => getData(evt, 'mode')} value={dataInputRow['mode']}>
//                         <option value="" selected> </option>
//                         <option value="remote">Remote</option>
//                         <option value="moving">Moving</option>
//                         <option value="office">Office</option>
//                     </select>
//                 </div>
//                 <div className="profileupdate-input">
//                     <select onChange={(evt) => getData(evt, 'skill')} value={dataInputRow['skill']}>
//                         <option value="" selected> </option>
//                         <option value="low">Low</option>
//                         <option value="medium">Medium</option>
//                         <option value="high">High</option>
//                     </select>
//                 </div>
//             </div>
//         </div>
//     );

//     function getData(evt, key) {
//         var dataInputTmp={};
//         var tmp = isRowSelected ? dataInputRow : dataInput;
//         var kk = evt.target.name != null && evt.target.name != '' ? evt.target.name : key;
//         var value = evt.target.value;

//         if (value == 'true')
//             tmp[kk] = true;
//         else if (value == 'false')
//             tmp[kk] = false;
//         else if (value == '')
//             delete (tmp[kk]);
//         else if (!isNaN(value)) {
//             tmp[kk] = parseInt(value);
//         }
//         else
//             tmp[kk] = value;

//         if (isRowSelected)
//             setDataInputRow(tmp);
//         else
//             setDataInput(tmp);


//     for (var key in dataInput) {
//         dataInputTmp[key] = dataInput[key];
//       };

//         var listTmpRows = cloneTableData(tableData['orig']);
//         var result = recorsiveGetData(listTmpRows, dataInputTmp);
//         setTableData(prv => ({ ...prv, ['table']: result }));
//     }

//     function recorsiveGetData(data, filters) {
//         if ((Object.keys(filters)).length == 0) {
//             return data;
//         };
//         var key = Object.keys(filters)[0];
//         data = filters[key] == true || filters[key] == false || !isNaN(filters[key]) ? data.filter(k => { return k[key] == (filters[key]) }) : data.filter(k => { return k[key].startsWith(filters[key]) });
//         delete (filters[key]);
//         var res = recorsiveGetData(data, filters);
//         return res;
//     };

//     function cloneTableData(tableData) {
//         var rows = {};
//         var listRows = [];
//         tableData.map(k => {
//             for (var key in k) {
//                 rows[key] = k[key];
//             };
//             listRows.push(rows);
//             rows = {};
//         });
//         return listRows;
//     }

//     function getRow(evt, data) {
//         setIsRowSelected(true);
//         setDataInputRow(data);

//         evt.currentTarget.className = 'testcolor';
//         var tt = preRow;
//         if (preRow != '')
//           preRow.className = 'standardcolor';
//         setPreRow(evt.currentTarget);
//     }

//     function clear() {
//         var tmp = {};
//         tmp =isRowSelected? dataInputRow:dataInput;
//         for (const [key, value] of Object.entries(tmp)) {
//             tmp[key] = '';
//         }
//         setDataInput(tmp);
//         setDataInputRow(tmp);
//         setDataInput({});
//         setDataInputRow({});

//         setIsRowSelected(false);
//         setTableData(prv => ({ ...prv, ['table']: prv['orig'] }));
//     }

//     function submit(evt) {
//         setReport({});
//         var tmp = {};
//         tmp['report'] = dataInputRow;
//         service.updateAssetData(tmp)
//             .then(async (data) => {

//                 var listTmpRows = cloneTableData(data.data);
//                 setTableData(prv => ({ ...prv, ['table']: data.data }));
//                 setTableData(prv => ({ ...prv, ['orig']: listTmpRows }));
//                 setTableKey(Object.keys(data.data[0]));

//                 clear();
//                 var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
//                 setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
//             })
//             .catch((err) => {
//                 if (err?.response != null)
//                     setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
//                 else
//                     setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
//             });
//     };

//     return (
//         <div className='profileupdate-main'>

//             <div className="profileupdate-button">
//                 <div className="profileupdate-clear">
//                     <button onClick={clear}>Clear</button>
//                 </div>
//                 <div className="profileupdate-action">
//                     <button onClick={submit}>Update </button>
//                 </div>
//             </div>

//             {!isRowSelected && (
//                 selectOption
//             )}
//             {isRowSelected && (
//                 selectOptionRow
//             )}

//             <div className="profileupdate-table">
//                 <table>
//                     <thead>
//                         <tr>
//                             {tableKey.map(x => (
//                                 (() => {
//                                     if (x != 'id') {
//                                         return (
//                                             <th>{x}</th>
//                                         )
//                                     }
//                                 })()
//                             ))
//                             }
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {tableData['table'].length > 0 ?
//                             tableData['table'].map(k => (
//                                 <tr onClick={(evt) => getRow(evt, k)} style={{
//                                     backgroundColor: color
//                                   }}>
//                                     {tableKey.map(kk => (
//                                         (() => {
//                                             if (kk != 'id') {
//                                                 return (
//                                                     k[kk] == true ? <td>true</td> : k[kk] == false ? <td>false</td> : <td>{k[kk]}</td>
//                                                 )
//                                             }
//                                         })()
//                                     ))}
//                                 </tr>
//                             )) : ''}
//                     </tbody>
//                 </table>

//             </div>

//             {report['ishttp'] != null ? <p className={report['ishttp']}>  {report['response']}  </p> : ''}
//         </div>
//     );
// };






import { useEffect, useState } from "react";
import useService from '../useService';
import Select from 'react-select'
import './profileupdate.css';

export default function Profileupdate(props) {
    const [options, setOptions] = useState({});
    const [dataInput, setDataInput] = useState({});
    const [dataInputRow, setDataInputRow] = useState({});
    const [assetValue, setAssetValue] = useState({ 'data': '', 'value': '' });
    const [fksResult, setFksResult] = useState([]);
    const [tableKey, setTableKey] = useState([]);
    const [tableData, setTableData] = useState({ table: '', orig: '', fk: '' });
    const [tableSchema, settableSchema] = useState([]);
    const service = useService();
    const [report, setReport] = useState({});
    const [flag, setFlag] = useState(true);
    const [isRowSelected, setIsRowSelected] = useState(false);
    const [color, setColor] = useState();
    const [preRow, setPreRow] = useState('');
    const [isCleared, setIsCleared] = useState(false);

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            boxShadow: '0 2px 4px rgba(0,0,0,.2)',
            height: '38px',
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected ? 'hotpink' : 'white',
        }),
    };

    async function getProfile() {
        var obj = {};
        var tables = ['service', 'customer', 'people', 'technology'];
        await service.postProfile(tables)
            .then(async (data) => {
                var res = data.data;
                for (var k in res) {
                    obj[k] = [];
                    res[k].forEach(kk => {
                        obj[k].push({ value: kk.id, label: kk.name });
                    });
                }
                setAssetValue(prv => ({ ...prv, ['data']: data.data }));
                setOptions(obj);

                service.getAssetData('report')
                    .then(async (data) => {

                        if (Object.keys(data.data).length == 0 || data.data === null || data.data === 'undefined') {
                            return null;
                        }

                        var listTmpRows = cloneTableData(data.data);
                        setTableData(prv => ({ ...prv, ['table']: data.data }));
                        setTableData(prv => ({ ...prv, ['orig']: listTmpRows }));
                        setTableKey(Object.keys(data.data[0]));

                        var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
                        setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
                    })
                    .catch((err) => {
                        if (err?.response != null)
                            setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
                        else
                            setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
                    });

                var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
            })
            .catch((err) => {
                if (err?.response != null)
                    setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
                else
                    setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
            });
    };
    useEffect(() => {
        getProfile();
    }, []);

    const selectOption = (
        <div className="profileupdate-data">
            <div className="profileupdate-value">
                {Object.keys(assetValue['data']).map(k => (
                    <div className="profileupdate-input">
                        {/* <Select value={assetValue['value'][k]} options={options[k]} onChange={(e) => getData(e, k)} className="select" styles={customStyles} placeholder={'select ' + k} /> */}
                        <label>{k}</label>
                        <select onChange={(evt) => getData(evt, k)} className="select" placeholder={'select ' + k} value={isRowSelected ? dataInputRow[k] : dataInput[k]}>
                            <option selected={isRowSelected ? dataInputRow[k] : dataInput[k]}>{isRowSelected ? dataInputRow[k] : dataInput[k]}</option>
                            {assetValue['data'][k].map(xx => (<option value={xx.name}>{xx.name}</option>))}
                        </select>
                    </div>
                ))}
                <div className="profileupdate-input">
                    <label>Duration</label>
                    <input value={isRowSelected && !isCleared ? dataInputRow['duration'] : isCleared ? '' : dataInput['duration']} name='duration' type="number" onChange={(evt) => getData(evt, 'duration')} className='input' />
                </div>
                <div className="profileupdate-input">
                    <label>Note</label>
                    <input type="text" onChange={(evt) => getData(evt, 'note')} className='input' value={isRowSelected && !isCleared ? dataInputRow['note'] : isCleared ? '' : dataInput['note']} name='note'  />
                </div>
                <div className="profileupdate-input">
                <label>Mode</label>
                <select onChange={(evt) => getData(evt, 'mode')} value={isRowSelected && !isCleared ? dataInputRow['mode'] : isCleared ? '' : dataInput['mode']}>
                        <option value="" selected> </option>
                        <option value="remote">Remote</option>
                        <option value="moving">Moving</option>
                        <option value="office">Office</option>
                    </select>
                </div>
                <div className="profileupdate-input">
                <label>Skill Level</label>
                <select onChange={(evt) => getData(evt, 'skill')} value={isRowSelected && !isCleared ? dataInputRow['skill'] : isCleared ? '' : dataInput['skill']}>
                        <option value="" selected> </option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                    </select>
                </div>
            </div>
        </div>
    );

    function getData(evt, key) {
        setIsCleared(false);
        var dataInputTmp = {};
        var tmp = isRowSelected ? dataInputRow : dataInput;
        var kk = evt.target.name != null && evt.target.name != '' ? evt.target.name : key;
        var value = evt.target.value;

        if (value == 'true')
            tmp[kk] = true;
        else if (value == 'false')
            tmp[kk] = false;
        else if (value == '')
            delete (tmp[kk]);
        else if (!isNaN(value)) {
            tmp[kk] = parseInt(value);
        }
        else
            tmp[kk] = value;

        if (isRowSelected)
            setDataInputRow(tmp);
        else
            setDataInput(tmp);


        for (var key in dataInput) {
            dataInputTmp[key] = dataInput[key];
        };

        var listTmpRows = cloneTableData(tableData['orig']);
        var result = recorsiveGetData(listTmpRows, dataInputTmp);
        setTableData(prv => ({ ...prv, ['table']: result }));
    }

    function recorsiveGetData(data, filters) {
        if ((Object.keys(filters)).length == 0) {
            return data;
        };
        var key = Object.keys(filters)[0];
        data = filters[key] == true || filters[key] == false || !isNaN(filters[key]) ? data.filter(k => { return k[key] == (filters[key]) }) : data.filter(k => { return k[key].startsWith(filters[key]) });
        delete (filters[key]);
        var res = recorsiveGetData(data, filters);
        return res;
    };

    function cloneTableData(tableData) {
        var rows = {};
        var listRows = [];
        tableData.map(k => {
            for (var key in k) {
                rows[key] = k[key];
            };
            listRows.push(rows);
            rows = {};
        });
        return listRows;
    }

    function getRow(evt, data) {
        setIsCleared(false);
        setIsRowSelected(true);
        setDataInputRow(data);

        evt.currentTarget.className = 'testcolor';
        var tt = preRow;
        if (preRow != '')
            preRow.className = 'standardcolor';
        setPreRow(evt.currentTarget);
    }

    function clear() {
        setIsCleared(true);
        var tmp = {};
        tmp = isRowSelected ? dataInputRow : dataInput;
        for (const [key, value] of Object.entries(tmp)) {
            tmp[key] = '';
        }
        setDataInput(tmp);
        setDataInputRow(tmp);
        setDataInput({});
        setDataInputRow({});

        setIsRowSelected(false);
        setTableData(prv => ({ ...prv, ['table']: prv['orig'] }));
    }

    function submit(evt) {
        setReport({});
        var tmp = {};
        tmp['report'] = dataInputRow;
        service.updateAssetData(tmp)
            .then(async (data) => {

                var listTmpRows = cloneTableData(data.data);
                setTableData(prv => ({ ...prv, ['table']: data.data }));
                setTableData(prv => ({ ...prv, ['orig']: listTmpRows }));
                setTableKey(Object.keys(data.data[0]));

                clear();
                var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
                setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
            })
            .catch((err) => {
                if (err?.response != null)
                    setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
                else
                    setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
            });
    };

    return (
        <div className='profileupdate-main'>

            <div className="profileupdate-button">
                <div className="profileupdate-clear">
                    <button onClick={clear}>Clear</button>
                </div>
                <div className="profileupdate-action">
                    <button onClick={submit}>Update </button>
                </div>
            </div>

            {selectOption}

            <div className="profileupdate-table">
                <table>
                    <thead>
                        <tr>
                            {tableKey.map(x => (
                                (() => {
                                    if (x != 'id') {
                                        return (
                                            <th>{x}</th>
                                        )
                                    }
                                })()
                            ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {tableData['table'].length > 0 ?
                            tableData['table'].map(k => (
                                <tr onClick={(evt) => getRow(evt, k)} style={{
                                    backgroundColor: color
                                }}>
                                    {tableKey.map(kk => (
                                        (() => {
                                            if (kk != 'id') {
                                                return (
                                                    k[kk] == true ? <td>true</td> : k[kk] == false ? <td>false</td> : <td>{k[kk]}</td>
                                                )
                                            }
                                        })()
                                    ))}
                                </tr>
                            )) : ''}
                    </tbody>
                </table>

            </div>

            {report['ishttp'] != null ? <p className={report['ishttp']}>  {report['response']}  </p> : ''}
        </div>
    );
};



