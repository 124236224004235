import { useEffect, useState } from "react";
import useService from '../useService';
import Select from 'react-select'
import './profile.css';

export default function Profile(props) {
  const [options, setOptions] = useState({});
  const [dataInput, setDataInput] = useState({});
  const [assetValue, setAssetValue] = useState({ 'data': '', 'value': '' });
  const [fksResult, setFksResult] = useState([]);
  const [tableSchema, settableSchema] = useState([]);
  const service = useService();
  const [report, setReport] = useState({});
  const [tableData, setTableData] = useState({ table: '', key: '' });
  let assetList = [];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      boxShadow: '0 2px 4px rgba(0,0,0,.2)',
      height: '38px',
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'white' : 'black',
      backgroundColor: state.isSelected ? 'hotpink' : 'white',
    }),
  };

  async function getProfile() {
    var obj = {};
    var tables = ['service', 'customer', 'people', 'technology'];
    await service.postProfile(tables)
      .then(async (data) => {
        var res = data.data;
        for (var k in res) {
          obj[k] = [];
          res[k].forEach(kk => {
            obj[k].push({ value: kk.id, label: kk.name });
          });
        }
        setAssetValue(prv => ({ ...prv, ['data']: data.data }));
        setOptions(obj);
        var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
      })
      .catch((err) => {
        if (err?.response != null)
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
        else
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
      });
  };
  useEffect(() => {
    getProfile();
  }, []);

  const selectOption = (
    <div className="profile-data">
      <div className="profile-value">
        {Object.keys(assetValue['data']).map(k => (
          <div className="profile-input">
            {/* <Select options={options[k]} value={dataInput[k]} onChange={(e) => getData(e, k)} className="select"  styles={customStyles}  placeholder={'select ' + k} /> */}
           <label>{k}</label>
            <select onChange={(evt) => getData(evt, k)} className="select" placeholder={'select ' + k}>
              {/* <option selected={dataInput[k]}>{dataInput[k]}</option> */}
            <option></option>
              {assetValue['data'][k].map(xx => (<option value={xx.name}>{xx.name}</option>))}
            </select>
          </div>
        ))
        }
        <div className="profile-input">
          <label>Duration</label>
          <input name='duration' type="number" onChange={(evt) => getData(evt, 'duration')} value={dataInput['duration']} className='input'  />
        </div>
        <div className="profile-input">
        <label>Note</label>
        <input type="text" onChange={(evt) => getData(evt, 'note')} className='input' value={dataInput['note']}  />
        </div>
        <div className="profile-input">
        <label>Mode</label>
          <select onChange={(evt) => getData(evt, 'mode')} value={dataInput['mode']}>
            <option value="" selected> </option>
            <option value="remote">Remote</option>
            <option value="moving">Moving</option>
            <option value="office">Office</option>
          </select>
        </div>
        <div className="profile-input">
        <label>Skill Level</label>
          <select onChange={(evt) => getData(evt, 'skill')} value={dataInput['skill']}>
            <option value="" selected> </option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
        </div>
      </div>
    </div>
  );

  function getData(evt, key) {
    var value = evt.label != null ? evt.label : evt.target.value;
    setDataInput(prv => ({ ...prv, [key]: value }));
    //    setAssetValue(prv => ({ ...prv, [key]: obj }));
  }

  function clear() {
    var tmp = {};
    tmp = dataInput;
    for (const [key, value] of Object.entries(tmp)) {
      tmp[key] = '';
    }
    setDataInput(tmp);
    //  setTableData(prv => ({ ...prv, ['table']: prv['orig'] }));
  }

  function submit(evt) {
    setReport({});
    var tmp = {};
    tmp['report'] = dataInput;
    var t = assetList[0];
    service.postAssetData(tmp)
      .then(async (data) => {
        setTableData(prv => ({ ...prv, ['table']: data.data }));
        setTableData(prv => ({ ...prv, ['key']: Object.keys(data.data[0]) }));
        var response = data.status == 200 ? 'Transaction Succesfully' : 'Transaction Failed'
        setReport(prv => ({ ...prv, response: 'Response: ' + response + " \n  Status: " + data.status, ishttp: 'ok' }));
        clear();
      })
      .catch((err) => {
        if (err?.response != null)
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.response.data.detail + " \n  Status: " + err.response.statusText, ishttp: 'ko' }));
        else
          setReport(prv => ({ ...prv, authorize: false, response: 'Response: ' + err.message, ishttp: 'ko' }));
        clear();
      });
  };

  return (
    <div className='profile-main'>

      <div className="profile-action">
        <button onClick={submit}>Insert</button>
      </div>

      {selectOption}

      
      {Object.keys(tableData['table']).length > 0 && (
        <div className="profile-table">
          <table>
            <thead>
              <tr>
                {tableData['key'].map(x => (
                  (() => {
                    if (x != 'id') {
                      return (
                        <th>{x}</th>
                      )
                    }
                  })()
                ))
                }
              </tr>
            </thead>
            <tbody>
              {tableData['table'].length > 0 ?
                tableData['table'].map(k => (
                  <tr>
                    {tableData['key'].map(kk => (
                      (() => {
                        if (kk != 'id') {
                          return (
                            k[kk] == true ? <td>true</td> : k[kk] == false ? <td>false</td> : <td>{k[kk]}</td>
                          )
                        }
                      })()
                    ))}
                  </tr>
                )) : ''}
            </tbody>
          </table>
        </div>
      )}


      {report['ishttp'] != null ? <p className={report['ishttp']}>  {report['response']}  </p> : ''}
    </div>
  );
};








