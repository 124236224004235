import { useEffect, useState } from "react";
import Login from './component/login';
import Menu from './component/menu';
import useService from './useService';


export default function App() {
  var schemaTable = [];
  const [inputLogin, setInputLogin] = useState({ user: '', pass: '', authorize: null, route: false, response: '', status: '', ishttp: null });
  const [menuSelection, setMenuSelection] = useState({ selection: 'selection', title: '', function: null, icon: 'faCoffee' });
  const [schema, setSchema] = useState([]);
  const classNameLogin = inputLogin['authorize'] ? 'ok' : 'ko';
  const service = new useService();

  function getSchema() {
    var schemaTableList = [];
    var count = 0;
    var tst = {};
    const schemaTable = ['fk', 'table', 'foreigntable', 'foreignkey']
    service.getSchema()
      .then(async (data) => {
        var t = data.data;
        var obj = {};

        t.forEach(k => {
          ((k.constraint_name).split('_')).forEach(item => {
            obj[schemaTable[count]] = item;
            count++;
          });
          count = 0;
          schemaTableList.push(obj);
          obj = {};
        });
        setSchema(schemaTableList);
      })
      .catch((err) => {
      });
  }

  useEffect(() => {
    getSchema();
  }, []);

  return (
    <div className='app-main' >
      {!inputLogin['route'] ? <Login il={inputLogin} sil={setInputLogin}></Login> : <Menu ms={menuSelection} sms={setMenuSelection}  schema={schema} il={inputLogin} sil={setInputLogin}></Menu>}
      {/* {inputLogin['authorize'] == null ? <Menu ms={menuSelection} sms={setMenuSelection}  schema={schema}  il={inputLogin} sil={setInputLogin}></Menu> : ''} */}

      <div className="app-title">
        <div id='name'>{menuSelection['title'] != null ? menuSelection['title'] : 'menu'} </div>
      </div>
    </div>
  );
};
